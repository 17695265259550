import Navbar from "./Navbar";
import Footer from "./Footer";
import "./HomeMain.css";
import { people } from "../data/AllData";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  ProjectAlldataServices,
  Mobile_buttom_data,
  MobileButtonBranding,
  settings,DataTestomonial
} from "../data/AllData";
const BrandingServices = () => {
  
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((prevIndex) => prevIndex + 1);
    }, 3000);
    return () => clearInterval(slider);
  }, []);
  const navigate = useNavigate();
  function HandelSubmit(item){
    navigate(`/portfolio/${item.slug}`, { state: { item } });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section
        className="Services_secction Software_developent_Desktop"
      >
        <div className="container Services_section_container">
          <div className="row">
            <div className="col-12 Services_section_container_Cloumn">
              <div className="Images_services">
                <img
                  src="/Asstes/Images/AboutImages/Group 1000006160 (1).png"
                  alt="images"
                />
              </div>
              <div className="Text_section">
                <h1>
                  <span style={{ color: "#F58327" }}>
                    Your brand, your story ,
                  </span>{" "}
                  <br></br>are told perfectly.
                </h1>
                <p>
                  In today`s aggressive marketplace, a sturdy logo
                  identification is <br></br>extra important than ever. It's the specific
                  essence that units your <br></br>enterprise aside and connects you
                  together along with your goal <br></br>audience. Our branding offerings
                  move past only a brand or tagline <br></br> we create a complete logo
                  approach that captures your vision,<br></br> values, and personal.
                </p>
                <div className="Moblie_Button_section_main">
                  <Link to="/contact_us">
                    <div className="Navbar_botton_cloud">
                      <img
                        src="/Asstes/Images/LogoButton.png"
                        alt="LogoButton"
                      />
                      <button>
                        GET QUOTE
                        <img src="/Asstes/Images/RightArrow.svg" alt="images" />
                      </button>
                    </div>
                  </Link>
                  <div className="Mobile_Button_text_section">
                    <a href="tel:+91 9311572747">
                      <a href="tel:+91 9311572747">
                        <button>Call Now</button>
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
      <div className="container Mobile_all">
        <div className="col-lg-6 Mobile_section">
        <div className="Text_section">
                <h1>
                  <span style={{ color: "#F58327" }}>Innovating the future,</span>{" "}
                  <br></br> one line of code at a time.
                </h1>
                <p>
                At Reptile India, we're experts at creating custom software to match your<br></br> specific business requirements. Utilising the newest technology, our skilled<br></br> development team creates creative and effective applications.

                </p>
                <div className="Moblie_Button_section_main">
                <Link to="/contact_us">
                    <div className="Navbar_botton_cloud">
                      <img
                        src="/Asstes/Images/LogoButton.png"
                        alt="LogoButton"
                      />
                      <button>
                        GET QUOTE
                        <img src="/Asstes/Images/RightArrow.svg" alt="images" />
                      </button>
                    </div>
                  </Link>
                  <div className="Mobile_Button_text_section">
                  <a href="tel:+91 9311572747">
                      <button>Call Now</button>
                    </a>
                  </div>
                </div>
              </div>
        </div>
        <div className="col-lg-6 Mobile_section">
          <img src="/Asstes/MobileImages/SoftwareDevelopment.png" alt="images" />
        </div>
      </div>
    </section>
    <section>
        <div className="Mobile_section_Mission">
          <div className="TExt_services_section">
            <h4>Tech Stack</h4>
            <h3 style={{ paddingBottom: "20px" }}>
            LEVEL UP WITH OUR TECH SKILLS!
            </h3>
          </div>
          <div className="container">
        <div className="row Teachnoloy_section_main">
          {MobileButtonBranding.map((item, index) => (
            <div className="col-lg-4 Technology_section" key={index}>
              <div>
                <img src={item.Images} alt="images" />
                <p>{item.label}</p>
              </div>
            
            </div>
            
          ))}
        </div>
      </div>
        </div>
      </section>
      <section
        className="Mobile_Information"
       
      >
        <div className="TExt_services_section">
          <h4>Services</h4>
          <h3 style={{ paddingBottom: "20px" }}>WHAT WE DO</h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 Mobile_All_information_section">
              <p>
              In today's aggressive marketplace, a robust logo identification is extra essential than ever. It's the precise essence that units your commercial enterprise aside and connects you together along with your goal audience. Our branding offerings pass past only a brand or tagline; we create a complete logo approach that captures your vision, values, and personal.

              </p>
            </div>
            <div className="col-12 images_section_mobile_information">
              <img
                src="/Asstes/Images/Services/Rectangle 192 (5).png"
                alt="images"
              />
            </div>
          </div>
        </div>
        <div className="container TEXT_button_section_main">
          <div className="row">
            <div className="col-12 Button_section">
              <div className="row">
              <div className="col-lg-4 Button_section_left">
                <img
                  src="/Asstes/Images/Services/Button9.png"
                  alt="images"
                />
              </div>
              <div className="col-lg-8 Right_section_text">
              <h1 className="Mobile_Heading">BRAND AUDIT</h1>
                <p>
                Our logos are unique, memorable, and perfectly represent your company.
                </p>
              </div>
              </div>
            </div>
            <div className="col-12 Button_section">
              <div className="row">
              <div className="col-lg-8 Right_section_text">
              <h1 className="Mobile_Heading">BRAND POSITIONING</h1>
                <p>
                We create captivating brand language that successfully conveys the benefits and values of your company.
                </p>
              </div>
              <div className="col-lg-4 Button_section_left">
                <img
                  src="/Asstes/Images/Services/Button10.png"
                  alt="images"
                />
              </div>
              </div>
            </div>
            <div className="col-12 Button_section">
              <div className="row">
              <div className="col-lg-4 Button_section_left">
                <img
                  src="/Asstes/Images/Services/Button11.png"
                  alt="images"
                />
              </div>
              <div className="col-lg-8 Right_section_text">
              <h1 className="Mobile_Heading">BRAND MESSAGING</h1>
                <p>
                To make sure your brand stands out from the competition, we assist you in defining its distinct place in the market.
                </p>
              </div>
              </div>
            </div>
            <div className="col-12 Button_section">
              <div className="row">
              <div className="col-lg-8 Right_section_text">
              <h1 className="Mobile_Heading">LOGO DESIGN</h1>
                <p>
                We thoroughly examine your current brand to find its advantages, disadvantages, and room for expansion.
                </p>
              </div>
              <div className="col-lg-4 Button_section_left">
                <img
                  src="/Asstes/Images/Services/Button12.png"
                  alt="images"
                />
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'100px'}}>
        <div className="Mobile_section_Mission">
          <div className="TExt_services_section">
            <h4>WHY CHOOSE US</h4>
            <h3 style={{ paddingBottom: "20px" }}>
            OUR EXPERTISES
            </h3>
          </div>
          <div className="container">
            <div className="row Teachnoloy_section_main">
              {Mobile_buttom_data.map((item, index) => (
                <div className="Mobile_button_section" key={index}>
                  <div>
                    <img src={item.Buttom_images} alt="images" />
                   
                  </div>
                
                </div>
                
              ))}
            </div>
          </div>
        </div>
      </section>
      <div className="REview_testimonal_mobile">
      <div className="container">
        <div className="row">
        <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4>Review</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div className="Images_review_section">
              <img src="/Asstes/Images/Review.png" alt="images" />
            </div>
        </div>
      </div>
      <section className="section">

      <div className="section-center">
        {people.map((person, personIndex) => {
          const { id, image, name, title, quote } = person;
          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article key={id} className={position}>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <p className="title">{title}</p>
              <p className="text">{quote}</p>
            </article>
          );
        })}
      </div>
    </section>
    </div>

    <div
        className="Review_section_main Review_section_main_desktop"
        
      >
        <div className="container Review_section">
          <div className="row">
            <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4>Review</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div className="Images_review_section">
              <img src="/Asstes/Images/Review.png" alt="images" />
            </div>
            <div className="col-12" style={{ padding: "0", margin: "0" }}>
              <div className="Top_section"></div>
              <div className="Slider_section_main_section">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_1" key={index}>
                            <h3>Mr. Aakash</h3>
                            <p>Carecone Technologies Pvt Ltd did a fantastic job working on our Suraksha Code mobile application. Throughout the process, their team demonstrated professionalism, responsiveness, & support.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_2" key={index}>
                            <h3>Mr. Vivek</h3>
                            <p>Having collaborated with Reptile India on a number of <br></br>projects, I've always been pleased with their professionalism and knowledge.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_3" key={index}>
                            <h3>Mr. Bhojveer Singh</h3>
                            <p>Working with the Reptile India team was a breeze. They were quick to adjust to changes in the scope of our project and consistently attentive to our needs.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_4" key={index}>
                            <h3>Mr. Vishnu Gola</h3>
                            <p>Although I was first dubious about outsourcing app development, I'm really happy that I went with Reptile India.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_5" key={index}>
                            <h3>Mr. Avinash</h3>
                            <p>There is excellent customer service at Reptile India. They were always willing to talk to us and respond to our inquiries.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_6" key={index}>
                            <h3>Mr .  Ajay Sinha</h3>
                            <p>In every aspect, Reptile India surpassed our expectations. <br></br>They succeeded in meeting both deadlines and budgets <br></br>while producing excellent software.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Slider>
                  <div className="overSlider_main"></div>
                </div>
              </div>
              <div className="Top_section"></div>
            </div>
          </div>
        </div>
      </div>



      <div className="container Our_team_section_main">
        <div className="TExt_services_section">
          <h4 style={{ textAlign: "left", paddingTop: "0" }}>OUR WORK</h4>
          <h3
            style={{
              textAlign: "left",
              paddingTop: "0",
              textTransform: "uppercase",
            }}
          >
            Crafting Seamless Experiences
          </h3>
        </div>
        <div className="row">
          {ProjectAlldataServices.map((TeamData, index) => (
            <div key={index} className="col-lg-4 team_images">
          
              {/* <Link to={`${baseURL}${TeamData.slug}`} className="team_link"> */}
              <div className="Slider_first_all_ppppp">
                <div className="First_flip_section_ppppp">
                  <div className="Team_images_section_ppppp">
                    <div className="Images_team_ppppp">
                      <center>
                       <img src={TeamData.video} alt='images' />
                      </center>
                    </div>
                  </div>
                  <div className="TEam_button_ppppp" onClick={()=>{HandelSubmit(TeamData)}}>
                    <h5>{TeamData.name}</h5>
                    <h6>{TeamData.Name2} </h6>
                  </div>
                </div>
                <div className="slider_secound_ppppp">
                  <div className="Team_images_section_ppppp">
                    <h2 className="Team_text_ppppp">{TeamData.Team_text}</h2>
                    <h2 className="Team_value_ppppp">{TeamData.Team_value}</h2>
                    <div className="Images_team_ppppp">
                      <center>
                        <img src={TeamData.images} alt="Team images" />
                      </center>
                    </div>
                  </div>
                  <div className="TEam_button_ppppp">
                    <h5>vikas kumar</h5>
                    <h6>WEB DESIGNER</h6>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
      
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default BrandingServices;
