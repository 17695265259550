import Navbar from "./Navbar";
import Footer from "./Footer";
const Refundpolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row">
         <div className="col-12 TERMCONDITION_main">
         <div className="TERMCONDITION_section">
            <h1 style={{color:'white'}}>REFUND POLICY</h1>
            <p>Refund Policy</p>
            <p>This Refund Policy governs the manner in which reptileindia.co.in initiate the refund upon your request for PRODUCT NAME. This privacy policy applies to this software service offered by www.reptileindia.co.in, which is owned and operated by Carecone Technologies Private Limited.</p>
            <p>At Reptile, we take pride in the services delivered by us and guarantee your satisfaction with our services and support. We constantly improve and strive to deliver the best software solutions to you. However, in case you are not satisfied with our services, please contact us immediately and we will correct the situation, provide a refund or offer credit that can be used for future Reptile orders.</p>
            <p>Not Satisfied? Let us know!
            If you're not satisfied with the service, simply contact us at info@reptileindia.co.in to initiate the refund process.</p>
            <h4>1.REFUND POLICY</h4>
            <p>When a payment of fee is made to Reptile, the fees paid in advance are retained by Reptile in a client book of account. Reptile will earn the fees upon working on a client's matter. During an engagement, Reptile earns fee at different rates and different times depending on the completion of various milestones. Refund cannot be provided for earned fee because resources and man hours spent on delivering the service are non-returnable in nature. Further, we can't refund or credit any money paid to government entities, software and security companies or to other third parties with a role in processing your order. Under any circumstance, Reptile shall be liable to refund only upto the fee paid by the client.</p>
           <h4>2.PROCEDURE FOR REQUESTING REFUND</h4>
          <p>Refund request can be initiated by sending mail to info@reptileindia.co.in. All refund requests must be made within 60 days of purchase. Refund request over 60 days will not be accepted and only credit will be provided for completion of service / change of service. We will complete processing your refund request within 4 to 5 weeks of receiving all the information required for processing refund like reason for refund, bank details for processing request, etc.</p>
          <h4>3.FACTORS OUTSIDE OUR CONTROL</h4>
          <p>We cannot guarantee the results or outcome of your particular procedure. Problems beyond our control and are not covered by this guarantee or eligible for refund.</p>
          <h4>4.FORCE MAJEURE</h4>
          <p>Reptile shall not be considered in breach of its satisfaction guarantee policy or default under any terms of service, and shall not be liable to the Client for any cessation, interruption, or delay in the performance of its obligations by reason of earthquake, flood, fire, storm, lightning, drought, landslide, hurricane, cyclone, typhoon, tornado, natural disaster, act of God or the public enemy, epidemic, famine or plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labor strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a "Force Majeure Event").</p>
          <h4>5.CANCELLATION FEE</h4>
          <p>
          Since we're incurring costs and dedicating time, manpower, technology resources and effort to your service or document preparation, our guarantee only covers satisfaction issues caused by Reptile - not changes to your situation or your state of mind. In case you require us to hold the processing of a service, we will hold the fee paid until you are ready to commence the service.</p>
          <p>Before processing any refund, we reserve the right to make best effort to complete the service. In case, you are not satisfied with the service, a cancellation fee of 20% + earned fee + fee paid to any third party would be applicable. In case of change of service, the cancellation fee would not be applicable.</p>
                    
          </div>
         </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Refundpolicy;
