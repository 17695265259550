import Navbar from "./Navbar";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { MissionVissionData } from "../data/AllData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [openIndex, setOpenIndex] = useState(null);
  return (
    <div>
      <Navbar />
      <section
        className="About_section_main"
      >
        <div className="container About_images_Hero">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <img
                src="/Asstes/Images/AboutImages/Group 1000006095.png"
                alt="images"
              />
            </div>
            <div
              className="col-12 About_text_section"
              style={{ padding: "0", margin: "0" }}
            >
              <h1>
                <span style={{ color: "#7E7E7E" }}> HI THERE! </span> WELCOME TO
                <br></br> OUR FAMILY
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container Services_section_main"
      >
        <div className="TExt_services_section" style={{ paddingTop: "30px" }}>
          <h4 style={{ textAlign: "left" }}>WHO WE ARE</h4>
          <h3 style={{ textAlign: "left" }}>ABOUT OUR COMPANY</h3>
        </div>
        <div className="row">
          <div className="col-lg-6 
          Services_section_main_Main_pages_all_left"
          style={{ padding: "0", margin: "0" }}
          >
            <div className="Services_images_video">
              <p>
              Carecone technologies, a dynamic software development company, is
              the embodiment of innovation and expertise, brought to life
              through its partnership with Reptile India. With a relentless
              pursuit of excellence, Carecone technologies is dedicated to
              providing cutting-edge IT solutions that empower businesses and
              individuals to reach new heights. Our commitment to delivering
              exceptional software solutions is fueled by a deep understanding
              of the evolving technological landscape and a passion for creating
              innovative products that drive growth and efficiency. At Carecone
              technologies, we believe that technology is not just a tool, but a
              catalyst for transformation. By harnessing the power of
              technology, we help our clients unlock their full potential and
              achieve their goals.
              </p>
            </div>
          </div>
          <div className="col-lg-6 Services_section_main_Main_pages_all_right">
            {MissionVissionData.map((item, index) => (
              <div className="TExt_our_mission_section" key={index}>
                <div className="Vision_section">
                  <div className="Main_icon_section">
                    <div className="Images">
                      <img src={item.VissionImages} alt="images" />
                      <h5>{item.Heading}</h5>
                    </div>
                    <div className="icon">
                      {" "}
                      <i
                        className={`fa ${
                          openIndex === index ? "fa-minus" : "fa-plus"
                        }`}
                        onClick={() =>
                          setOpenIndex(openIndex === index ? null : index)
                        }
                      ></i>
                    </div>
                  </div>
                  <div className="answer">
                    {openIndex === index && <p>{item.Answer}</p>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section
        className="Client_section_main"
       
      >
        <div className="container Client_section">
          <div className="row">
            <div
              className="col-12 client_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4>Our Clients</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div
              className="col-12 Client_Brand_section"
              style={{ padding: "0", margin: "0" }}
            >
              <h3              >
                Brand that trust us
              </h3>
              <div id="infinite" class="highway-slider">
                <div class="container-fluid highway-barrier">
                  <ul class="highway-lane">
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (1).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (2).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (3).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (4).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (5).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (6).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (7).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (8).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (1).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (2).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (3).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (4).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (5).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (6).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Asstes/Images/Group 1000005947 (7).png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Images_logo.png" alt="images" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container Services_section_main"
      >
        <div className="TExt_services_section">
          <h4 style={{ textAlign: "left" }}>ABOUT FOUNDER</h4>
          <h3 style={{ textAlign: "left" }}>MEET THE FOUNDER </h3>
        </div>
        <div className="row">
          <div className="col-lg-6 About_section_main_Main_pages_all_left">
            <img
              src="/Asstes/Images/AboutImages/Group 1000006058.png"
              alt="monaj kumar"
            />
          </div>
          <div className="col-lg-6 About_section_right_text">
            <p>
              Company dedicated to be a leading provider of innovative IT
              solutions, recognized for our expertise, reliability, and
              commitment to customer satisfaction. With a passion for It
              Solution Service, he has spent 2 years building a company that is
              at the forefront of IT Solution Service. Driven by a relentless
              pursuit of excellence, Manoj Kumar has established Reptile India
              as a leader in Software development. Manoj Kumar’s journey began in
              New Delhi, where he discovered Software development.
            </p>
            <div className="About_section_OueVission">
              <div className="About_section_OueVission_button">
                <div className="Images_and_vission">
                  <img
                    src="/Asstes/Images/AboutImages/science 3.svg"
                    alt="images"
                  />
                  <h5>Product Innovator</h5>
                </div>
                <div className="Images_and_vission">
                  <img
                    src="/Asstes/Images/AboutImages/science 3.svg"
                    alt="images"
                  />
                  <h5>Innovative Leader</h5>
                </div>
              </div>
              <div className="About_section_OueVission_button">
                <div className="Images_and_vission">
                  <img
                    src="/Asstes/Images/AboutImages/science 3.svg"
                    alt="images"
                  />
                  <h5>Business Strategy</h5>
                </div>
                <div className="Images_and_vission" style={{ width: "270px" }}>
                  <img
                    src="/Asstes/Images/AboutImages/science 3.svg"
                    alt="images"
                  />
                  <h5>Detail-Oriented</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container Value_section"
       
      >
        <div className="row">
          <div className="col-12">
            <img src="/Asstes/Images/AboutImages/Value.png" alt="images" />
          </div>
        </div>
      </div>

      {/* <div
        className="container Our_team_section_main"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="200"
      >
         <div
          className="TExt_services_section"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="200"
        >
          <h4>- OUR TEAM</h4>
          <h3>MEET OUR TEAM </h3>
        </div> 
         <div
          className="row"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="200"
        >
          {TeamsData.map((TeamData, index) => (
            <div key={index} className="col-4 team_images">
              <div className="Slider_first_all">
                <div className="First_flip_section">
                  <div className="Team_images_section">
                    <h2 className="Team_text">{TeamData.Team_text}</h2>
                    <h2 className="Team_value">{TeamData.Team_value}</h2>
                    <div className="Images_team">
                      <center>
                        <img src={TeamData.images} alt="Team images" />
                      </center>
                    </div>
                  </div>
                  <div className="TEam_button">
                    <h5>{TeamData.TEamName}</h5>
                    <h6>{TeamData.Team_work}</h6>
                  </div>
                </div>
                <div className="slider_secound">
                  <div className="Team_images_section">
                    <h2 className="Team_text">{TeamData.Team_textBack}</h2>
                    <h2 className="Team_value">{TeamData.Team_valueBack}</h2>
                    <div className="Images_team">
                      <center>
                        <img src={TeamData.imagesBack} alt="Team images" />
                      </center>
                    </div>
                  </div>
                  <div className="TEam_button">
                    <h5>{TeamData.TEamNameBack}</h5>
                    <h6>{TeamData.Team_workBack}</h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> 
      </div> */}

      <div
        className="container GALLERY_section_main"
     
      >
        <div className="TExt_services_section">
          <h4 style={{ textAlign: "left" }}>GALLERY</h4>
          <h3 style={{ textAlign: "left" }}>OUR AGENCY SNAP</h3>
        </div>
        <div className="row">
          <div className="col-lg-7 Gallery_section_main">
            <div className="animation_main">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                onSlideChange={() => console.log("Slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <div className="Images_slider">
                    <img
                      src="/Asstes/Images/AboutImages/Images_team/IMG-20240322-WA0038.jpg"
                      alt="images"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Images_slider">
                    <img
                      src="/Asstes/Images/AboutImages/Images_team/IMG20230808184311.jpg"
                      alt="images"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Images_slider">
                    <img
                      src="/Asstes/Images/AboutImages/Images_team/Office_Group_Pic.jpg"
                      alt="images"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-lg-5 Gallery_section_main_small">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 4000, // Delay in milliseconds
                disableOnInteraction: false,
              }}
              modules={[Autoplay]} // Include Autoplay module here
              onSlideChange={() => console.log("Slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/WhatsApp Image 2024-09-13 at 12.56.39_7cba8b31.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/WhatsApp Image 2024-09-13 at 12.56.39_c7feb41b.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/WhatsApp Image 2024-09-13 at 12.56.39_e5037043.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/WhatsApp Image 2024-09-13 at 12.56.40_6750bccd.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="col-lg-5 Gallery_section_main_small">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 2000, // Delay in milliseconds
                disableOnInteraction: false,
              }}
              modules={[Autoplay]} // Include Autoplay module here
              onSlideChange={() => console.log("Slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/WhatsApp Image 2024-09-13 at 12.56.40_b2969b79.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/media_20240916_153710_1368198464123745942.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/WhatsApp Image 2024-09-13 at 12.56.41_6f78d851.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/WhatsApp Image 2024-09-13 at 12.56.39_c7feb41b.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="col-lg-7 Gallery_section_main">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              onSlideChange={() => console.log("Slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/IMG-20240322-WA0038.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/media_20240916_153710_854288281566414392.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/IMG20230808184311.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Images_slider">
                  <img
                    src="/Asstes/Images/AboutImages/Images_team/Office_Group_Pic.jpg"
                    alt="images"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default About;
