import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useState } from "react";
import { people } from "../data/AllData";
import "./HomeMain.css";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import Slider from "react-slick";
import {
  ServicesData,
  ProjectAlldataServices,
  settings,DataTestomonial
} from "../data/AllData";
const Services = () => {
  
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((prevIndex) => prevIndex + 1);
    }, 3000);
    return () => clearInterval(slider);
  }, []);
  const navigate = useNavigate();
  function HandelSubmit(item){
    navigate(`/portfolio/${item.slug}`, { state: { item } });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div
        className="container PortFolio_section_main"
        
      >
        <div className="row">
          <div className="col-12 PortFlio_section"style={{padding:'0',margin:'0'}}>
            <img
              src="/Asstes/Images/AboutImages/Services.png"
              alt="images"
            />
          </div>
        </div>
      </div>

      <div
        className="container Services_section_main"
        id="Services"
        style={{ paddingTop: "50px" }}
        
      >
        <div className="TExt_services_section">
          <h4>Our Services</h4>
          <h3>What we are offering</h3>
        </div>
        <div className="row">
          {ServicesData.map((item, index) => (
            <div
              className="col-lg-4 Services_section_Images"
              key={index}
              
            >
              <Link to={item.href} className="Link_undeline">
                <div className="Services_images">
                  <div className="images">
                    <img src={item.Images} alt="images" />
                  </div>
                  <h5 dangerouslySetInnerHTML={{ __html: item.label }} />

                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div
        className="Mobile_section_Mission"
        style={{ paddingTop: "40px" }}
        
      >


        {/* <div className="container">
          <div className="row">
            {MobileButtonServices.map((item, index) => (
              <div className="col-4" key={index}>
                <div>
                  <img src={item.Images} alt="images" />
                  <p>{item.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
      <div className="REview_testimonal_mobile">
      <div className="container">
        <div className="row">
        <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4>Review</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div className="Images_review_section">
              <img src="/Asstes/Images/Review.png" alt="images" />
            </div>
        </div>
      </div>
      <section className="section">

      <div className="section-center">
        {people.map((person, personIndex) => {
          const { id, image, name, title, quote } = person;
          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article key={id} className={position}>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <p className="title">{title}</p>
              <p className="text">{quote}</p>
            </article>
          );
        })}
      </div>
    </section>
    </div>



    <div
        className="Review_section_main Review_section_main_desktop"
        
      >
        <div className="container Review_section">
          <div className="row">
            <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4> Review</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div className="Images_review_section">
              <img src="/Asstes/Images/Review.png" alt="images" />
            </div>
            <div className="col-12" style={{ padding: "0", margin: "0" }}>
              <div className="Top_section"></div>
              <div className="Slider_section_main_section">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_1" key={index}>
                            <h3>Mr. Aakash</h3>
                            <p>Carecone Technologies Pvt Ltd did a fantastic job working on our Suraksha Code mobile application. Throughout the process, their team demonstrated professionalism, responsiveness, & support.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_2" key={index}>
                            <h3>Mr. Vivek</h3>
                            <p>Having collaborated with Reptile India on a number of <br></br>projects, I've always been pleased with their professionalism and knowledge.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_3" key={index}>
                            <h3>Mr. Bhojveer Singh</h3>
                            <p>Working with the Reptile India team was a breeze. They were quick to adjust to changes in the scope of our project and consistently attentive to our needs.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_4" key={index}>
                            <h3>Mr. Vishnu Gola</h3>
                            <p>Although I was first dubious about outsourcing app development, I'm really happy that I went with Reptile India.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_5" key={index}>
                            <h3>Mr. Avinash</h3>
                            <p>There is excellent customer service at Reptile India. They were always willing to talk to us and respond to our inquiries.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_6" key={index}>
                            <h3>Mr .  Ajay Sinha</h3>
                            <p>In every aspect, Reptile india surpassed our expectations. <br></br>They succeeded in meeting both deadlines and budgets <br></br>while producing excellent software.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Slider>
                  <div className="overSlider_main"></div>
                </div>
              </div>
              <div className="Top_section"></div>
            </div>
          </div>
        </div>
      </div>





      <div className="container Our_team_section_main">
        <div className="TExt_services_section">
          <h4 style={{ textAlign: "left", paddingTop: "0" }}>OUR WORK</h4>
          <h3
            style={{
              textAlign: "left",
              paddingTop: "0",
              textTransform: "uppercase",
            }}
          >
            Crafting Seamless Experiences
          </h3>
        </div>
        <div className="row">
          {ProjectAlldataServices.map((TeamData, index) => (
            <div key={index} className="col-lg-4 team_images">
          
              {/* <Link to={`${baseURL}${TeamData.slug}`} className="team_link"> */}
              <div className="Slider_first_all_ppppp">
                <div className="First_flip_section_ppppp">
                  <div className="Team_images_section_ppppp">
                    <div className="Images_team_ppppp">
                      <center>
                       <img src={TeamData.video} alt='images' />
                      </center>
                    </div>
                  </div>
                  <div className="TEam_button_ppppp" onClick={()=>{HandelSubmit(TeamData)}}>
                    <h5>{TeamData.name}</h5>
                    <h6>{TeamData.Name2} </h6>
                  </div>
                </div>
                <div className="slider_secound_ppppp">
                  <div className="Team_images_section_ppppp">
                    <h2 className="Team_text_ppppp">{TeamData.Team_text}</h2>
                    <h2 className="Team_value_ppppp">{TeamData.Team_value}</h2>
                    <div className="Images_team_ppppp">
                      <center>
                        <img src={TeamData.images} alt="Team images" />
                      </center>
                    </div>
                  </div>
                  <div className="TEam_button_ppppp">
                    <h5>vikas kumar</h5>
                    <h6>WEB DESIGNER</h6>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
      
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Services;
