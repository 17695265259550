import Navbar from "./Navbar";
import Footer from "./Footer";
import {useLocation} from "react-router-dom";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {
    settings,
    MobileButtonBranding,
    DataTestomonial,
  } from "../data/AllData";
const EmmMobile = (MobileFeaturesData)=>{
  const location = useLocation();
  const DataItem = location.state?.MobileFeaturesData;
  const {headData, dataThree,dataTwo,features} = DataItem;
  console.log(headData)
    return(
        <div>
        <Navbar />
      <section className="EmmMobile_section">
       <div className="container">
              <div className="row">
          <div className="col-lg-6 EmmMobile_imagesover">
          <h3>{headData.tagline }</h3>
       
          <p>{headData.description}</p>
         <div className="button_emm_section">
         <Link to='/contact_us'> <button style={{background:'#F58327',color:'white',border:'none'}}>Request a Demo</button></Link>
          <button><a href="tel:+91 9311572747"> Call Now </a></button>
         </div>
          </div>
          <div className="col-lg-6 EmmMobile_imagesover">
            <img src={headData.image}alt="images" />
          </div>
        </div>
        </div>
      </section>


 <section>
    <div className="Mobile_section_Mission">
      <div className="TExt_services_section">
        <h4>Tech Stack</h4>
        <h3 style={{ paddingBottom: "20px" }}>
        LEVEL UP WITH OUR TECH SKILLS!
        </h3>
      </div>
      <div className="container">
        <div className="row Teachnoloy_section_main">
          {MobileButtonBranding.map((item, index) => (
            <div className="col-lg-4 Technology_section" key={index}>
              <div>
                <img src={item.Images} alt="images" />
                <p>{item.label}</p>
              </div>
            
            </div>
            
          ))}
        </div>
      </div>
    </div>
  </section>


          <section>
    <div className="EmmMobile_section_coding container">

        <div className="row">
            <div className="col-lg-6">
                <h1>{dataTwo.tagline}</h1>
                <p>{dataTwo.description}</p>
            </div>
            <div className="col-lg-6 images">
                <img src={dataTwo.image}alt="images"/>
            </div>
        </div>
    </div>
 </section>
          <section>
          <section
    className="Client_section_main"
   
  >
    <div className="container Client_section">
      <div className="row">
        <div
          className="col-12 client_ssection_perent"
          style={{ padding: "0", margin: "0" }}
         
        >
          <div className="Right_line"></div>
          <div className="TExt_section_client">
            <h4> Our Clients</h4>
          </div>
          <div className="left_line"></div>
        </div>
        <div
          className="col-12 Client_Brand_section"
          style={{ padding: "0", margin: "0" }}
        >
          <h3
           
          >
            Brand that trust us
          </h3>
          <div
            id="infinite"
            class="highway-slider"
           
          >
            <div class="container-fluid highway-barrier">
              <ul class="highway-lane">
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (1).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (2).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (3).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (4).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (5).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (6).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (7).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947.png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (8).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (1).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (2).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (3).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (4).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (5).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (6).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Group 1000005947 (7).png" alt="images" />
                </li>
                <li class="highway-car">
                  <img src="/Asstes/Images/Images_logo.png" alt="images" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
          </section>

          <section>
    <div className="EmmMobile_section_coding container">

        <div className="row">
        <div className="col-lg-6 images">
                <img src={dataThree.image} alt="images"/>
            </div>
            <div className="col-lg-6">
                <h1>{dataThree.tagline}</h1>
                <p>{dataThree.description}</p>
            </div>
           
        </div>
    </div>
          </section>

          <section>
            <div className="container KeyFeatures_asection">
            <div className="TExt_services_section">
        <h1 style={{textAlign:'center',color:'#f5f5f5'}}>{features.heading}</h1>
        <p style={{ paddingBottom: "20px",textAlign:'center' }}>
       {features.description}
        </p>
      </div>
                <div className="row">
                    {
                        features.featureList.map((item, index)=>(
                            <div className="col-lg-4"key={index}>
                            <div className="Key_features_items">
                                <div className="Icon_features">
                                  <img src={item.icon} alt="images" />
                                </div>
                                <div className="Text_features_section">
                                    <h4>{item.heading}</h4>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>  
                            
                        ))
                    }
                   
                  
                    
                </div>
            </div>
          </section>


          <div
        className="Review_section_main Software_developent_Desktop"
        
      >
        <div className="container Review_section">
          <div className="row">
            <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4>Review</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div className="Images_review_section">
              <img src="/Asstes/Images/Review.png" alt="images" />
            </div>
            <div className="col-12" style={{ padding: "0", margin: "0" }}>
              <div className="Top_section"></div>
              <div className="Slider_section_main_section">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_1" key={index}>
                            <h3>Mr. Aakash</h3>
                            <p>Carecone Technologies Pvt Ltd did a fantastic job working on our Suraksha Code mobile application. Throughout the process, their team demonstrated professionalism, responsiveness, & support.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_2" key={index}>
                            <h3>Mr. Vivek</h3>
                            <p>Having collaborated with Reptile India on a number of <br></br>projects, I've always been pleased with their professionalism and knowledge.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_3" key={index}>
                            <h3>Mr. Bhojveer Singh</h3>
                            <p>Working with the Reptile India team was a breeze. They were quick to adjust to changes in the scope of our project and consistently attentive to our needs.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_4" key={index}>
                            <h3>Mr. Vishnu Gola</h3>
                            <p>Although I was first dubious about outsourcing app development, I'm really happy that I went with Reptile India.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_5" key={index}>
                            <h3>Mr. Avinash</h3>
                            <p>There is excellent customer service at Reptile India. They were always willing to talk to us and respond to our inquiries.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_6" key={index}>
                            <h3>Mr .  Ajay Sinha</h3>
                            <p>In every aspect, Reptile India surpassed our expectations. <br></br>They succeeded in meeting both deadlines and budgets <br></br>while producing excellent software.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Slider>
                  <div className="overSlider_main"></div>
                </div>
              </div>
              <div className="Top_section"></div>
            </div>
          </div>
        </div>
      </div>
        <Footer />
    </div>
    )
}
export default EmmMobile