import Footer from "./Footer";
import Navbar from "./Navbar";
import { useNavigate } from "react-router";
import {Productdata} from "../data/AllData";
const PRODUCT = () =>{
    const navigate = useNavigate();
    const ProductData = (ProData) => {
      if (ProData && ProData.route) {
        navigate(`/product/${ProData.route}`, { state: { ProData } });
        return;
      } console.error('ProData is missing when navigating');
    };
    return(
        <div>
         
            <Navbar />
         
            <div className="container PortFolio_section_main">
        <div className="row">
          <div className="col-12 PortFlio_section">
            <img src="/Asstes/Images/AboutImages/OUR_PRODUCTS.png" alt="images" />
          </div>
        </div>
      </div>

      <div className="container PortFoliosection_main">
        <div className="TExt_services_section" style={{ paddingTop: "50px" }}>
          <h4 style={{ textAlign: "left" }}>Our Product</h4>
          <h3 style={{ textAlign: "left" }}>Where Innovation Meets Excellence</h3>
        </div>
      <div className="row">

          {Productdata.map((item, index) => (
            
            <div
              className={'col-12 PortFolio_section_all_mainTow Products_section'}
              key={item.id}
              onClick={()=>ProductData(item)}
            >
              <div className="row">
                <div className="col-lg-5 PortFolio_section_all">
                  <img src={item.TrackoImages} alt="images" />
                </div>
                <div className="col-lg-7 PortFolio_section_text2">
                  <h1 style={{ color: 'white' }}>{item.TrackoName}</h1>
                  <p>{item.TrackoInformation}</p>
                  <div className="Features_section">
                    <div className="Features_main">
                      <ul>
                        <li>{item.Features1}</li>
                        <li>{item.Features2}</li>
                      </ul>
                    </div>
                    <div className="Features_main">
                      <ul>
                        <li>{item.Features3}</li>
                        <li>{item.Features4}</li>
                      </ul>
                    </div>
               
                  </div>
                  <button className="Product_btn">
                    VIEW MORE
                  </button>
                </div>
                
              </div>
            </div>
          ))}
    
        </div>   
      </div>
            <Footer />
        </div>
    )
}
export default PRODUCT;