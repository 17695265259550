import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react';
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./components/About";
import Mobile from "./components/MobileDev";
import Cloud from "./components/Cloud";
import DigitalMarket from "./components/Digital";
import BrandingServices from "./components/BrandingServices";
import WebDevelopment from "./components/WebDevelopment";
import SoftwareDevelopment from "./components/SoftwareDevelopmet";
import PortFolio from "./components/PortFolio";
import PortFolioDeatils from "./components/PortFolioDeatils";
import Contact from "./components/Contact";
import Thanku from "./components/ThankuPAges";
import Services from "./components/Services";
import AOS from 'aos';
import 'aos/dist/aos.css';
import PRODUCT from "./components/PRODUCT ";
import ProductDeatils from "./components/ProductDeatils";
import PrivacyPolicymobilesecurity from "./components/PrivacyPolicymobilesecurity";
import Privacypolicy from "./components/PRIVACY";
import Refundpolicy from "./components/Refundpolicy";
import Termcondition from "./components/Termcondition";
import EmmMobile from "./components/EmmMobile";
import MobileEmmDetail from "./components/MobileEmmDetail";
import DynamicPage from "./components/DynamicPage";
import ReptileEmiPlus from "./components/reptileemisecurityplus";
import ReptileEmiPlusTermCondation from "./components/reptileemisecurityplus_ terms_condition";



const App = ()=>{
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
      <Route exact path="/" element={<> <Home /> <DynamicPage path="Home" url="Home" /></> }> </Route>
      <Route exact path="/portfolio" element={<> <PortFolio /> <DynamicPage path="portfolio" url="portfolio" /></> }> </Route>
      <Route exact path="/about_us" element={<> <About /> <DynamicPage path="about_us" url="about_us" /></> }> </Route>
      <Route exact path="/privacy-policy" element={<> <Privacypolicy /> <DynamicPage path="privacy_policy" url="privacy_policy" /></> }> </Route>
      <Route exact path="/terms-condition" element={<> <Termcondition /> <DynamicPage path="term_condition" url="term_condition" /></> }> </Route>
      <Route exact path="/refund-policy" element={<> <Refundpolicy /> <DynamicPage path="refund_policy" url="refund_policy" /></> }> </Route>
      <Route exact path="/contact_us" element={<> <Contact /> <DynamicPage path="contact_us" url="contact_us" /></> }> </Route>
      <Route exact path="services" element={<> <Services /> <DynamicPage path="services" url="services" />  </> }> </Route>
      <Route exact path="/services/app_development" element={<> <Mobile /> <DynamicPage path="app_development" url="app_development" /></> }> </Route>
      <Route exact path="/services/cloud_computing_service" element={<> <Cloud /> <DynamicPage path="cloud_computing_service" url="cloud_computing_service" /></> }> </Route>
      <Route exact path="/services/branding_service" element={<> <BrandingServices /> <DynamicPage path="branding_service" url="branding_service" /></> }> </Route>
      <Route exact path="/services/digital_marketing" element={<> <DigitalMarket /> <DynamicPage path="digital_marketing" url="digital_marketing" /></> }> </Route>
      <Route exact path="/services/web_development" element={<> <WebDevelopment /> <DynamicPage path="web_development" url="web_development" /></> }> </Route>
      <Route exact path="/services/software_development" element={<> <SoftwareDevelopment /> <DynamicPage path="software_development" url="software_development " /></> }> </Route> 
      <Route exact path="/product" element={<> <PRODUCT /> <DynamicPage path="product" url="product" />  </> }> </Route>
      <Route path="/product/:route" element={<> <ProductDeatils /> <DynamicPage path="ProductDeatils" url="ProductDeatils" />  </> }></Route>
      <Route path="Navbar" element={<Navbar />}></Route>
      <Route path="Footer" element={<Footer />}></Route> 
      <Route path="Thanku" element={<Thanku />} />
      <Route path="/product/enterprise-management/:id" element={<MobileEmmDetail />} />
      <Route path="/product/enterprise-managements/:id" element={<EmmMobile />} />
      <Route path="/portfolio/:id" element={<PortFolioDeatils />} />
      <Route path="privacy-policy-anti-theft" element={<PrivacyPolicymobilesecurity />}></Route>
      <Route path="reptileemisecurityplus_privacy_policy" element={<ReptileEmiPlus />}></Route>
      <Route path="reptileemisecurityplus_term_condation" element={<ReptileEmiPlusTermCondation />}></Route>
       {/* <Route path='/services/app-development' element={<Mobile />} ></Route>  */}
       {/* <Route path='/services/cloud-services' element={<Cloud />} ></Route>  */}
       {/* <Route path="/services/branding-services" element={<BrandingServices />}></Route>  */}
       {/* <Route path="/services/digital-marketing" element={<DigitalMarket />}></Route>  */}
       {/* <Route path="/services/web-development" element={<WebDevelopment />}></Route>  */}
       {/* <Route path="/services/software-development" element={<SoftwareDevelopment />}></Route>   */}
        {/* <Route path="our-products" element={<PRODUCT />}></Route> */}
        {/* <Route path="services" element={<Services />} /> */}
        {/* <Route path="tracko" element={<Tracko />}></Route> */}
        {/* <Route path="contact-us" element={<Contact />} /> */}
         {/* <Route path="about-us" element={<About />}></Route> */}
        {/* <Route path="privacy-policy" element={<Privacypolicy />} /> */}
        {/* <Route path="refund-policy" element={<Refundpolicy />} /> */}
        {/* <Route path="terms-condition" element={<Termcondition />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
export default App;


