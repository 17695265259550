import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useState } from "react";
import Slider from "react-slick";
import "./HomeMain.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { people } from "../data/AllData";
import {
  ServicesData,
  ExpreanseData,
  ButtonData,
  settings,
  DataTestomonial,
} from "../data/AllData";
const Home = () => {
  
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((prevIndex) => prevIndex + 1);
    }, 3000);
    return () => clearInterval(slider);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div>
         
      <Navbar />
     <section
        className="Hero_home">
        <div className="container First_section_Home">
          <div className="row">
            <div className="col-12"        >
              <h1>
                Delivering <span style={{ color: "#F58327" }}>top-tier</span>{" "}
                <br></br>software
                <span>
                  <img src="/Asstes/Images/Vector.png" alt="images" />
                </span>
                solutions
              </h1>
              <p>
                Custom software packages that are expertly made. Our expertise<br></br>
                lies in creating personalized software that precisely matches<br></br>
                your distinct business requirements.
              </p>
              <center>
                <div className="Moblie_Button_section_main">
                  <Link to="/contact_us">
                    <div className="Navbar_botton">
                      <img
                        src="/Asstes/Images/LogoButton.png"
                        alt="LogoButton"
                      />
                      <button>
                        GET QUOTE
                        <img src="/Asstes/Images/RightArrow.svg" alt="images" />
                      </button>
                    </div>
                  </Link>
                  <div className="Mobile_Button_text_section">
                    <a href="tel:+91 9311572747">
                      <button>Call Now</button>
                    </a>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
        <section
          className="Secound_section_hero"
        >
          <div className="Secound_hero_section">
            <div className="container ">
              <div className="row">
                <div className="col-12">
                  <div className="Social_media_section_images">
                    <img
                      src="/Asstes/Images/Image.png"
                      alt="images"
                      className="Home_images"
                    />
                    <img
                      src="/Asstes/Images/HomeNet.png"
                      alt="images"
                      className="Images_home_social"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="Home_button_images_section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img src="/Asstes/Images/SocialMedia.png" alt="images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div
        className="container Services_section_main"
        id="Services"
        style={{ paddingTop: "149px" }}
      >
        <div
          className="TExt_services_section"
        >
          <h4>Our Services</h4>
          <h3>What we are offering</h3>
        </div>
        <div className="row">
          {ServicesData.map((item, index) => (
            <div
              className="col-lg-4 col-md-6 col-12  Services_section_Images"
              key={index}
             
            >
              <Link to={item.href} className="Link_undeline">
                <div className="Services_images">
                  <div className="images">
                    <img src={item.Images} alt="images" />
                  </div>
                  <h5 dangerouslySetInnerHTML={{ __html: item.label }} />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <section
        className="Client_section_main"
       
      >
        <div className="container Client_section">
          <div className="row">
            <div
              className="col-12 client_ssection_perent"
              style={{ padding: "0", margin: "0" }}
             
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4> Our Clients</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div
              className="col-12 Client_Brand_section"
              style={{ padding: "0", margin: "0" }}
            >
              <h3
               
              >
                Brand that trust us
              </h3>
              <div
                id="infinite"
                class="highway-slider"
               
              >
                <div class="container-fluid highway-barrier">
                  <ul class="highway-lane">
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (1).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (2).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (3).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (4).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (5).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (6).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (7).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947.png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (8).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (1).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (2).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (3).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (4).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (5).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (6).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Group 1000005947 (7).png" alt="images" />
                    </li>
                    <li class="highway-car">
                      <img src="/Asstes/Images/Images_logo.png" alt="images" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

       <div
        className="Companay_experances"
       
      >
        <div className="container Companay_experances_main">
          <div className="row">
            <div className="col-12" style={{ padding: "0", margin: "0" }}>
              <div className="Timer_section_exprense">
                <img
                  src="/Asstes/Images/Group 1000005967.png"
                  alt="images"
                  className="Images_first"
                />
                <img
                  src="/Asstes/Images/Group 1000005968.png"
                  alt="images"
                  className="Images_secound"
                />
                <img
                  src="/Asstes/Images/Group 1000005969.png"
                  alt="images"
                  className="Images_thard"
                />
                <img
                  src="/Asstes/Images/Group 1000005970.png"
                  alt="images"
                  className="Images_fourthed"
                />
                <img
                  src="/Asstes/Images/Group 1000005971.png"
                  alt="images"
                  className="Images_fivethed"
                />
              </div>
            </div>
            {ExpreanseData.map((itemsImages, index) => (
              <div className="col-lg-4 Button_images_section" key={index}>
                <div className="Button_imGES">
                  <center>
                    {" "}
                    <img src={itemsImages.images} alt="images" />
                  </center>
                </div>
              </div>
            ))}
            <div className="col-12 button_last_section">
              {ButtonData.map((itembutton, index) => (
                <button key={index}>
                  <img src={itembutton.button} alt="images" className="Butn_images"/>
                </button>
              ))}
              <Link to="/contact_us">
              <div className="Navbar_botton Button_ex">
                <img src="/Asstes/Images/LogoButton.png" alt="LogoButton" />
                <button>
                  GET QUOTE
                  <img src="/Asstes/Images/RightArrow.svg" alt="images" />
                </button>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div> 

      <div
        className="Industry_section_main"
      
      >
        <div className="container">
          <div className="row">
            <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4>IT Industry</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div style={{ height: "120px", width: "100%" }}>
              <h1 className="Insdustery_heading">Build Software, Boost Business </h1>
            </div>
            <div className="col-lg-7 Insdustery_Images_section">
              <h1>
                150%<br></br> increase<br></br> business{" "}
              </h1>
            </div>
            <div className="col-lg-5 Insdustery_text_section">
              <h1>
                build <br></br>
                test <br></br>
                deploy<br></br>
              </h1>
            </div>

            <div className="col-lg-5 Industery_section_right_images_section">
              <div className="Images_section">
                <h6>Other Industry</h6>
                <p>Use pre-made templates to jumpstart creativity.</p>
                <img src="/Asstes/Images/Group 1000006029.png" alt="images" />
              </div>
            </div>
            <div className="col-lg-7 Industry_expertise_section_right">
              <h1>
                Industry <br></br>expertise
              </h1>
              <div id="Industery" class="highway-slider">
                <div class="highway-barrier">
                  <ul class="highway-lane">
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Healthcare</h2>
                      </div>
                    </li>
                    <li class="highway-car">
                      <div className="Slider_infinite">
                        <h2 className="Dotes">.</h2>{" "}
                        <h2 className="Marquee_section">Real state</h2>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="Review_section_main Software_developent_Desktop"
        
      >
        <div className="container Review_section">
          <div className="row">
            <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4>Review</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div className="Images_review_section">
              <img src="/Asstes/Images/Review.png" alt="images" />
            </div>
            <div className="col-12" style={{ padding: "0", margin: "0" }}>
              <div className="Top_section"></div>
              <div className="Slider_section_main_section">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_1" key={index}>
                            <h3>Mr. Aakash</h3>
                            <p>Carecone Technologies Pvt Ltd did a fantastic job working on our Suraksha Code mobile application. Throughout the process, their team demonstrated professionalism, responsiveness, & support.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_2" key={index}>
                            <h3>Mr. Vivek</h3>
                            <p>Having collaborated with Reptile India on a number of <br></br>projects, I've always been pleased with their professionalism and knowledge.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_3" key={index}>
                            <h3>Mr. Bhojveer Singh</h3>
                            <p>Working with the Reptile India team was a breeze. They were quick to adjust to changes in the scope of our project and consistently attentive to our needs.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_4" key={index}>
                            <h3>Mr. Vishnu Gola</h3>
                            <p>Although I was first dubious about outsourcing app development, I'm really happy that I went with Reptile India.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_5" key={index}>
                            <h3>Mr. Avinash</h3>
                            <p>There is excellent customer service at Reptile India. They were always willing to talk to us and respond to our inquiries.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="Slider_review_section">
                      <div className="slider_item_section">
                        {DataTestomonial.map((DataTestimonial, index) => (
                          <div className="Slide_section_child_6" key={index}>
                            <h3>Mr .  Ajay Sinha</h3>
                            <p>In every aspect, Reptile India surpassed our expectations. <br></br>They succeeded in meeting both deadlines and budgets <br></br>while producing excellent software.</p>
                            <div className="Fa_icon_testimonial">
                              {DataTestimonial.images.map(
                                (images, indexImages) => (
                                  <img
                                    key={indexImages}
                                    src={images}
                                    alt="images"
                                  />
                                )
                              )}
                              <p>5.0 rating</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Slider>
                  <div className="overSlider_main"></div>
                </div>
              </div>
              <div className="Top_section"></div>
            </div>
          </div>
        </div>
      </div>


      <div className="REview_testimonal_mobile">
      <div className="container">
        <div className="row">
        <div
              className="col-12 Insdustery_ssection_perent"
              style={{ padding: "0", margin: "0" }}
            >
              <div className="Right_line"></div>
              <div className="TExt_section_client">
                <h4> Review</h4>
              </div>
              <div className="left_line"></div>
            </div>
            <div className="Images_review_section">
              <img src="/Asstes/Images/Review.png" alt="images" />
            </div>
        </div>
      </div>
      <section className="section">

      <div className="section-center">
        {people.map((person, personIndex) => {
          const { id, image, name, title, quote } = person;
          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article key={id} className={position}>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <p className="title">{title}</p>
              <p className="text">{quote}</p>
            </article>
          );
        })}
      </div>
    </section>
    </div>
      <Footer />

    </div>
    </>

  );
};
export default Home;
