import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
const DynamicPage = ({ path, url }) => {
  const [apiData, setapiData] = useState({});
  const [canonical, setcanonical] = useState("");
  const baseurl = "https://www.reptileindia.co.in/";
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [pLink, setpLink] = useState("");

  useEffect(() => {
    console.log("Current URL Pathname:", window.location.pathname);
    handalpage();
  }, [location]);

  const handalpage = async () => {

    var currentPath = location.pathname.split("/").pop() || 'Home';  
    const route = ['emi_security','enterprise_mobility_management','daily_hisab','hisab_book'];
    const path = currentPath; 
  
    console.log("currentPath-->", currentPath);

    if (route.includes(`/${currentPath}`)) { 
      console.log('Matched one of the routes:', currentPath);
      return; 
    }
    try {
         const res = await axios.post(
        "https://www.reptileindia.co.in/laravel_website/api/get_seo_content",
        {
          route: path,
        }
      );
      if (res.data.result) {
        console.log("res.data:", res.data);
        setapiData(res.data.result);
        if (path == "reptile_tracko") {
          var pID = "reptile-tracko";
        } else {
          var pID = "";
        }

        setcanonical(res.data.result.canonical + url + pID);
      } else {
        if (path == "blog") {
          setcanonical(baseurl + path);
        }
      }
    } catch (error) {
      console.error("Error fetching SEO content:", error);
    }
  };
  useEffect(() => {
    setActiveLink(location.pathname);
    handalpage();
  }, [path]);
  return (
    <div>
      <Helmet>
        <title>{apiData.title}</title>
        <meta name="description" content={apiData.meta_description} />
        <meta name="description" content={apiData.meta_description} />
        <meta name="keywords" content={apiData.keywords} />
        <meta charSet="UTF-8" />
        <meta name="robots" content={apiData.robots} />
        <link rel="canonical" href={canonical} />
        <meta property="og:url" content={apiData.og_url} />
        <meta property="og:type" content={apiData.og_type} />
        <meta property="og:title" content={apiData.og_title} />
        <meta property="og:description" content={apiData.og_description} />
        <meta property="og:site_name" content={apiData.og_site_name} />
        <meta property="og:image" content={apiData.og_image} />
        <meta property="og:image:width" content={apiData.og_image_width} />
        <meta property="og:image:height" content={apiData.og_image_height} />
        <meta name="twitter:card" content={apiData.twitter_card} />
        <meta property="twitter:domain" content={apiData.twitter_domain} />
        <meta property="twitter:url" content={apiData.twitter_url} />
        <meta name="twitter:title" content={apiData.twitter_title} />
        <meta
          name="twitter:description"
          content={apiData.twitter_description}
        />
        <meta name="twitter:image" content={apiData.twitter_image} />
        <meta name="distribution" content="global" />
        <meta name="googlebot" content="allow, follow" />
        <meta name="revisit-after" content="1 day" />
        <meta
          name="website"
          content="App Development Company In Delhi, Reptile India"
        />
        <meta name="author" content="Reptile India" />
      </Helmet>
    </div>
  );
};

export default DynamicPage;
