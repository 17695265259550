import React, { Fragment, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./HomeMain.css";
function PrivacyPolicymobilesecurity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <>
        <Navbar />

        <>

          <div className="container">
            <div className="row">
              <div className="col-12 Privacypolicy_section_maion">
                <div className="Privacypolicy_section">
                  <h1>Privacy Policy for Reptile - AntiTheft</h1>
                  <p>Last Updated: 2024-09-10</p>

                  <h2 style={{color:'white'}}>1. Introduction</h2>
                  <p>
                    Welcome to Reptile - AntiTheft. Your privacy and security
                    are of utmost importance to us. This Privacy Policy explains
                    how we collect, use, disclose, and protect your information,
                    including for users who are children. By using our app, you
                    consent to the practices described in this policy.
                  </p>

                  <h2 style={{color:'white'}}>2. Information We Collect</h2>

                  <h3 style={{color:'white'}}>2.1 Personal Information</h3>
                  <p>
                    We collect certain types of information to provide you with
                    our anti-theft features and ensure a secure experience. For
                    users under the age of 13, we are committed to handling
                    personal information with extra care and obtaining parental
                    consent where required.
                  </p>
                  <p>
                    <strong>Registration Information:</strong> When setting up
                    the app, users may provide personal information such as
                    their name and age. We collect this information only with
                    parental consent for children under 13.
                  </p>
                  <p>
                    <strong>Profile Information:</strong> Users can create a
                    profile within the app, which may include additional details
                    such as preferences and settings. This information is
                    collected with parental consent for children under 13.
                  </p>

                  <h3 style={{color:'white'}}>2.2 Device and Usage Information</h3>
                  <p>
                    <strong>Device Details:</strong> We collect information
                    about your device, including model, operating system
                    version, and unique identifiers (e.g., IMEI, UDID).
                  </p>
                  <p>
                    <strong>Network Information:</strong> We gather data about
                    your network connection, such as IP address and connection
                    type, to enhance functionality and security.
                  </p>
                  <p>
                    <strong>App Interactions:</strong> We track how the app is
                    used, including interactions with features and time spent in
                    different sections, to improve performance and user
                    experience.
                  </p>
                  <p>
                    <strong>Diagnostic Data:</strong> We collect error logs and
                    crash reports to diagnose and resolve technical issues.
                  </p>

                  <h3 style={{color:'white'}}>2.3 Location and Media Data</h3>
                  <p>
                    <strong>Location Data:</strong> If you enable location-based
                    features, we collect real-time location data. This includes
                    data related to battery overcharge detection, charging
                    disconnect detection, and other location-based services.
                  </p>
                  <p>
                    <strong>Media Access:</strong> We request permission to
                    access media files (e.g., photos) if necessary for app
                    features such as alert notifications and media management.
                  </p>

                  <h3 style={{color:'white'}}>2.4 Permissions Required</h3>
                  <p>
                    Our app requires various permissions to function
                    effectively, and these are granted only with user consent.
                    The permissions include:
                  </p>
                  <ul style={{color:'white'}}>
                    <li>
                      <strong>Device Permissions:</strong> Access to device
                      features such as camera, microphone, and storage (e.g.,
                      android.permission.CAMERA,
                      android.permission.RECORD_AUDIO,
                      android.permission.READ_EXTERNAL_STORAGE,
                      android.permission.WRITE_EXTERNAL_STORAGE).
                    </li>
                    <li>
                      <strong>Location Permissions:</strong> Access to location
                      data, including precise and coarse location (e.g.,
                      android.permission.ACCESS_FINE_LOCATION,
                      android.permission.ACCESS_COARSE_LOCATION).
                    </li>
                    <li>
                      <strong>Special Permissions:</strong> Permissions for
                      specific functionalities, such as foreground services
                      (android.permission.FOREGROUND_SERVICE,
                      android.permission.FOREGROUND_SERVICE_LOCATION,
                      android.permission.FOREGROUND_SERVICE_MICROPHONE, etc.),
                      and device admin (android.permission.BIND_DEVICE_ADMIN).
                    </li>
                  </ul>

                  <h2 style={{color:'white'}}>3. How We Use Your Information</h2>

                  <h3 style={{color:'white'}}>3.1 Providing and Operating the App</h3>
                  <p>
                    <strong>Feature Functionality:</strong> We use data to
                    enable features such as battery overcharge detection, face
                    detection, motion detection, and more. This helps in
                    delivering the core functionalities of our anti-theft
                    services.
                  </p>
                  <p>
                    <strong>Performance:</strong> Data helps us monitor and
                    improve app performance and reliability.
                  </p>

                  <h3 style={{color:'white'}}>3.2 Personalization and Improvement</h3>
                  <p>
                    <strong>Customization:</strong> Information is used to
                    tailor the app experience, including personalized alerts and
                    notifications.
                  </p>
                  <p>
                    <strong>Feedback and Updates:</strong> We analyze user
                    feedback and usage data to enhance features and add new
                    functionalities.
                  </p>

                  <h3 style={{color:'white'}}>3.3 Communication</h3>
                  <p>
                    <strong>Notifications:</strong> We send notifications about
                    app updates, features, and security alerts. Parents can
                    manage notification settings.
                  </p>
                  <p>
                    <strong>Support:</strong> We use information to provide
                    support and resolve issues.
                  </p>

                  <h3 style={{color:'white'}}>3.4 Security and Protection</h3>
                  <p>
                    <strong>Fraud Prevention:</strong> Data helps us detect and
                    prevent fraud and misuse of the app.
                  </p>
                  <p>
                    <strong>Compliance:</strong> We use information to comply
                    with legal obligations and protect our rights.
                  </p>

                  <h2 style={{color:'white'}}>4. How We Share Your Information</h2>

                  <h3 style={{color:'white'}}>4.1 Service Providers</h3>
                  <p>
                    <strong>Third-Party Services:</strong> We may share
                    information with trusted partners who assist in app
                    operations, such as cloud services and support providers.
                  </p>

                  <h3 style={{color:'white'}}>4.2 Legal Requirements</h3>
                  <p>
                    <strong>Legal Obligations:</strong> Information may be
                    disclosed if required by law, regulation, or legal process.
                  </p>
                  <p>
                    <strong>Enforcement:</strong> We may share information to
                    enforce our terms of service and protect against legal
                    claims.
                  </p>

                  <h3 style={{color:'white'}}>4.3 Business Transactions</h3>
                  <p>
                    <strong>Mergers and Acquisitions:</strong> In the event of a
                    business transfer, such as a merger or acquisition,
                    information may be transferred as part of the transaction.
                  </p>

                  <h2 style={{color:'white'}}>5. Security of Your Information</h2>

                  <h3 style={{color:'white'}}>5.1 Technical Safeguards</h3>
                  <p>
                    <strong>Encryption:</strong> We use encryption to secure
                    data during transmission.
                  </p>
                  <p>
                    <strong>Access Controls:</strong> Access to data is
                    restricted to authorized personnel, and authentication
                    mechanisms are in place.
                  </p>

                  <h3 style={{color:'white'}}>5.2 Organizational Measures</h3>
                  <p>
                    <strong>Data Protection:</strong> We have policies to
                    safeguard information and regularly assess our security
                    practices.
                  </p>
                  <p>
                    <strong>Incident Response:</strong> We have procedures to
                    address and manage security incidents.
                  </p>

                  <h2 style={{color:'white'}}>6. Parental Consent and Control</h2>

                  <h3 style={{color:'white'}}>6.1 Parental Consent</h3>
                  <p>
                    <strong>Obtaining Consent:</strong> We obtain parental
                    consent before collecting personal information from children
                    under 13.
                  </p>
                  <p>
                    <strong>Parental Rights:</strong> Parents can review,
                    update, or delete their child’s information and manage
                    consent through the app or by contacting us.
                  </p>

                  <h3 style={{color:'white'}}>6.2 Account Management</h3>
                  <p>
                    <strong>Parental Controls:</strong> Parents can adjust
                    settings and permissions related to their child’s account.
                  </p>
                  <p>
                    <strong>Contact Us:</strong> For any privacy-related
                    questions or concerns, parents can contact us using the
                    details provided below.
                  </p>

                  <h2 style={{color:'white'}}>7. Your Choices and Rights</h2>

                  <h3 style={{color:'white'}}>7.1 Access and Correction</h3>
                  <p>
                    <strong>View and Update:</strong> Users can access and
                    update their personal information through app settings.
                  </p>
                  <p>
                    <strong>Account Deletion:</strong> Users can request
                    deletion of their account and data, subject to legal
                    requirements.
                  </p>

                  <h3 style={{color:'white'}}>7.2 Permissions</h3>
                  <p>
                    <strong>Device Permissions:</strong> Users can manage
                    permissions through device settings.
                  </p>
                  <p>
                    <strong>Notifications:</strong> Users can adjust
                    notification preferences within the app.
                  </p>

                  <h3 style={{color:'white'}}>7.3 Data Portability</h3>
                  <p>
                    <strong>Export Data:</strong> Users can request a copy of
                    their data for transfer.
                  </p>

                  <h3 style={{color:'white'}}>7.4 Opt-Out</h3>
                  <p>
                    <strong>Marketing Communications:</strong> Users can opt out
                    of marketing communications as specified in the app.
                  </p>

                  <h2 style={{color:'white'}}>8. Children’s Privacy</h2>

                  <h3 style={{color:'white'}}>8.1 Compliance with COPPA</h3>
                  <p>
                    Reptile - AntiTheft is committed to protecting the privacy
                    of children:
                  </p>
                  <p>
                    <strong>Data Collection:</strong> We comply with the
                    Children’s Online Privacy Protection Act (COPPA) and similar
                    regulations, ensuring we do not collect personal information
                    from children under 13 without parental consent.
                  </p>
                  <p>
                    <strong>Parental Involvement:</strong> Parents can review
                    and manage their child’s information and data collection.
                  </p>

                  <h2 style={{color:'white'}}>9. Changes to This Privacy Policy</h2>
                  <p>
                    We may update this Privacy Policy from time to time. Changes
                    will be posted on this page with an updated "Last Updated"
                    date. We encourage you to review the policy periodically.
                  </p>

                  <h2 style={{color:'white'}}>10. Contact Us</h2>
                  <p>
                    For questions or concerns about this Privacy Policy, please
                    contact us:
                  </p>
                  <div className="contact-info">
                    <p>Email: nidhi@reptileindia.co.in</p>
                    <p>
                      Address: 265, 2nd floor, Aggarwal City Plaza, Mangalam
                      Place, Rohini, New Delhi, Delhi, 110085
                    </p>
                    <p>Phone: +91 9311572747 , +91 8178334747</p>
                  </div>

                  <h2 style={{color:'white'}}>11. Data Protection Officer (DPO)</h2>
                  <p>
                    If applicable, you may contact our Data Protection Officer
                    for privacy-related queries:
                  </p>
                  <div className="contact-info">
                    <p>Name: Manoj Kumar</p>
                    <p>Email: manoj.kumar@reptileindia.co.in</p>
                    <p>Phone: +919212748200</p>
                  </div>

                  <h2 style={{color:'white'}}>12. International Users</h2>
                  <p>
                    If you access the app from outside [Your Country], your
                    information may be transferred to and processed in [Your
                    Country]. By using the app, you consent to the transfer and
                    processing of your information.
                  </p>

                  <h2 style={{color:'white'}}>13. Third-Party Links</h2>
                  <p>
                    The app may contain links to third-party websites. We are
                    not responsible for the privacy practices or content of
                    these third parties. We encourage you to review their
                    privacy policies.
                  </p>

                  <h2 style={{color:'white'}}>14. Cookies and Tracking Technologies</h2>
                  <p>
                    We use cookies and tracking technologies to enhance user
                    experience and analyze app usage. Users can manage cookie
                    preferences through browser settings.
                  </p>

                  <h2 style={{color:'white'}}>15. User Rights Under GDPR (If Applicable)</h2>
                  <p>
                    For residents of the European Union (EU), you have rights
                    under the General Data Protection Regulation (GDPR):
                  </p>
                  <ul style={{color:'white'}}>
                    <li>
                      <strong>Right to Access:</strong> Request access to your
                      personal data.
                    </li>
                    <li>
                      <strong>Right to Rectification:</strong> Request
                      correction of inaccurate data.
                    </li>
                    <li>
                      <strong>Right to Erasure:</strong> Request deletion of
                      data under certain conditions.
                    </li>
                    <li>
                      <strong>Right to Restrict Processing:</strong> Request
                      restriction of data processing.
                    </li>
                    <li>
                      <strong>Right to Data Portability:</strong> Request data
                      in a structured, commonly used format.
                    </li>
                    <li>
                      <strong>Right to Object:</strong> Object to processing
                      based on legitimate interests or direct marketing.
                    </li>
                  </ul>
                  <p>
                    To exercise these rights, contact us at the details provided
                    above.
                  </p>

                  <h2 style={{color:'white'}}>16. California Privacy Rights (If Applicable)</h2>
                  <p>
                    For California residents, the California Consumer Privacy
                    Act (CCPA) provides specific rights:
                  </p>
                  <ul style={{color:'white'}}>
                    <li>
                      <strong>Right to Know:</strong> Request information about
                      data collection, use, and sharing.
                    </li>
                    <li>
                      <strong>Right to Delete:</strong> Request deletion of
                      personal data.
                    </li>
                    <li>
                      <strong>Right to Opt-Out:</strong> Opt out of data sales
                      (note: we do not sell personal data).
                    </li>
                    <li>
                      <strong>Non-Discrimination:</strong> We will not
                      discriminate for exercising privacy rights.
                    </li>
                  </ul>
                  <p>
                    To exercise these rights, contact us using the information
                    provided above.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
        <Footer />
      </>
    </Fragment>
  );
}
export default PrivacyPolicymobilesecurity;
