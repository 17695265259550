import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import {FortFolioData} from "../data/AllData";
const PortFolio = () => {
  const [visibleItems, setVisibleItems] = useState(3);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  const navigate = useNavigate();
  const BlogData = (item) => {
    console.log(item);
    navigate(`/portfolio/${item.slug}`, { state: { item } });
  };

  const handleViewMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
  };
  return (
    <div>
      <Navbar />
      <div
        className="container PortFolio_section_main"
       
      >
        <div className="row">
          <div className="col-12 PortFlio_section">
          <img src="/Asstes/Images/Services/imgpsh_fullsize_anim (1).gif"alt="images" />
          </div>
        </div>
      </div>

      <div
        className="container PortFoliosection_main"
       
      >
        <div className="TExt_services_section" style={{ paddingTop: "50px" }}>
          <h4 style={{ textAlign: "left" }}>Our work</h4>
          <h3 style={{ textAlign: "left" }}>Where Vision Becomes Iconic</h3>
        </div>
        <div className="row">
          <div className="col-12 PortFolio_section_all_main">
            {FortFolioData.slice(0, visibleItems).map((item, index) => (
              <div className="row" key={item.id}>
                <div className="col-lg-5 PortFolio_section_all">
                  <img
                    src={item.imagesFortFolio}
                    alt="images"
                    onClick={() => BlogData(item)}
                  />
                </div>
                <div className="col-lg-7 PortFolio_section_text">
                  <div
                    className="PortFolio_main"
                    onClick={() => BlogData(item)}
                  >
                    <div className="PortFolio_secton_top">
                      <h5>Project : {item.ProjectName}</h5>
                      <p>{item.ProjectAnswer}</p>
                    </div>

                    <div className="PortFolio_secton_Bottom" key={index}>
                      {item.Label.map((Time, index) => (
                        <div className="Timer_section" key={index}>
                          <h1>{Time.TimerLabel}</h1>
                          <p>{Time.Increase}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <center>
              <div className="Button_section">
                {visibleItems < FortFolioData.length && (
                  <button onClick={handleViewMore}>View More</button>
                )}
              </div>
            </center>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PortFolio;
