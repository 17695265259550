import Navbar from "./Navbar";
import Footer from "./Footer";
const Privacypolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-12 Privacypolicy_section_maion">
            <div className="Privacypolicy_section">
              <h1>PRIVACY POLICY</h1>
              <p>
                This Privacy Policy governs the manner in which
                reptileindia.co.in collects, uses, maintains and discloses
                information collected from users (each, a "User") of the
                http://www.reptileindia.co.in website ("Site"). This privacy
                policy applies to the Site and offered by
                www.reptileindia.co.in, which is owned and operated by Carecone
                Technologies Private Limited.
              </p>
              <h4>1. PERSONAL IDENTIFICATION INFORMATION</h4>
              <p>
We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.<br></br>
Name<br></br>
E-mail.<br></br>
User's GPS Location (Conditional) for attendance/tasks
Background Location (Conditional) for automated expense
Device Model and network connectivity.
Camera for selfie attendance and while filling the pictures related filed task forms
Audio for field data collection in audio field.
Google Play Services</p>
<h4>2. NON-PERSONAL IDENTIFICATION INFORMATION</h4>
<p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers' utilized and other similar information.</p>
           <h4>3. WEB BROWSER COOKIES</h4>
           <p>Currently we do not use cookies. Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
           
           <h4>5. HOW WE PROTECT YOUR INFORMATION</h4>
           <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. For any such kind of queries you can also connect with us at info@reptileindia.co.in . Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.</p>
           <h4>6. SHARING YOUR PERSONAL INFORMATION</h4>
           <p>We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission.</p>
           <h4>7. GOOGLE ADSENSE</h4>
           <p>
           Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users based on their visit to our Site and other sites on the Internet. DART uses "non personally identifiable information" and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at http://www.google.com/privacy_ads.html</p>
            <h4>8. COMPLIANCE WITH CHILDREN'S ONLINE PRIVACY PROTECTION ACT</h4>
            <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Site from those we actually know are under 18, and no part of our website is structured to attract anyone under 18.</p>
            <h4>
            9. CHANGES TO THIS PRIVACY POLICY</h4>
            <p>Reptileindia.co.in have the discretion to update this privacy policy at any time. When we do, we will send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
            </p>
            <h4>
            10. CONFIDENTIALITY STATEMENT</h4>
            <p>At Reptileindia.co.in, we place a major emphasis on maintaining confidentiality of Client/User information and Information Security Management. The following are key features of our Confidentiality Agreement, applicable to all our clients/users.
 We do not disclose any information pertaining to our Clients or to any third parties.
 We do not use our Clients identity or Clients brand for our marketing purposes, without their written consent.
 We never provide our Client's name or Client's information to other prospective clients for marketing purposes.
 We do not sell or disclose our Client list or Client Information to third parties.</p>
            <h4>11. CONFIDENTIALITY AGREEMENT
            </h4>
            <p>It is understood and agreed to that our Client may provide certain information that is and must be kept confidential. To ensure the protection of such information, and to preserve any confidentiality necessary under patent and/or trade secret laws, it is agreed that:</p>
            
            <p>The Confidential Information to be disclosed can be described as and includes Invention description(s), technical and business information relating to proprietary ideas and inventions, ideas, patentable ideas, trade secrets, drawings and/or illustrations, patent searches, existing and/or contemplated products and services, research and development, production, costs, profit and margin information, finances and financial projections, customers, clients, marketing, and current or future business plans and models, regardless of whether such information is designated as "Confidential Information" at the time of its disclosure. Reptileindia.co.in shall limit disclosure of Confidential Information within its own organization to its directors, officers, partners, members, employees and/or independent contractors (collectively referred to as "affiliates") having a need to know. Reptileindia.co.in and affiliates will not disclose the confidential information obtained from the discloser unless required to do so by law.</p>
            <p>This Agreement imposes no obligation upon Reptileindia.co.in with respect to any Confidential Information</p>
            <p>(a) that was in Reptileindia.co.in possession before receipt from Client;</p>
            <p>(b) is or becomes a matter of public knowledge through no fault of Reptileindia.co.in;</p>
            <p>(c) is rightfully received by Reptileindia.co.in from a third party not owing a duty of confidentiality to the Reptileindia.co.in’s Client;</p>
            <p>(d) is disclosed without a duty of confidentiality to a third party by, or with the authorization of Reptileindia.co.in;</p>
            <p>(e) is public knowledge or the information is available in public domain; or</p>
            <p>(f) is independently derived by Reptileindia.co.in.</p>
            <p>This Agreement states the entire agreement between Reptileindia.co.in and its Client concerning the disclosure of Confidential Information. Any addition or modification to this Agreement must be made in writing and signed by the parties.
            If any of the provisions of this Agreement are found to be unenforceable, the remainder shall be enforced as fully as possible and the unenforceable provision(s) shall be deemed modified to the limited extent required to permit enforcement of the Agreement as a whole. You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.</p>
            
            <h4>
            12. CONTACT US</h4>
            <p>For any query contact us at REPTILEINDIA.CO.IN
Carecone Technologies Private Limited
Feel free to write us at info@reptileindia.co.in ,we have a designated person to answer all your queries.
Contact Us</p>
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Privacypolicy;
